
























































import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Utils } from '@/app/infrastructures/misc'
import EyeIcon from '@/app/ui/assets/eye_filled.vue'
import DownloadIcon from '@/app/ui/assets/icon_download_filled.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import controller from '@/app/ui/controllers/ManageCourierController'
import { ManageCourierHistoryUploadBulk } from '@/domain/entities/ManageCourier'
import { IHeaderCell, IOptions, ITableCell } from '@/data/infrastructures/misc/interfaces/manageCourier'
import { Dictionary } from 'vue-router/types/router'

@Component({
  components: {
    DataTableV2,
    PaginationNav,
    EyeIcon,
    DownloadIcon,
    LoadingOverlay,
  },
})
export default class ManageCourierHistoryUploadBulkyPage extends Vue {
  controller = controller

  parameters = {
    page: 1,
    perPage: 10,
  }

  headers = [
    this.headerCellMapper('Nama File', '255px'),
    this.headerCellMapper('Dikirim Oleh', '255px'),
    this.headerCellMapper('Tanggal Kirim', '200px'),
    this.headerCellMapper('Aksi', '200px'),
  ]

  tableData = <ITableCell[][]>[]

  perPageOptions: IOptions[] = [
    { label: '10', value: 10 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ]

  created(): void {
    const query = <Record<string, never>>this.$route.query
    this.parameters = {
      page: query.page | 1,
      perPage: query.perPage | 10,
    }

    this.fetchHistoryUploadBulk(true)
  }

  get params(): Record<string, number> {
    return {
      ...this.parameters,
    }
  }

  private onDownloadFile(id: number): void {
    const filepath = controller.manageCourierHistoryUploadBulkData.find(data => data.id === id)
      ?.filePath
    if (filepath) {
      window.open(process.env.VUE_APP_STORAGE_BASE_URL + '/' + filepath)
    }
  }

  private fetchHistoryUploadBulk(reset?: boolean): void {
    if (reset) {
      this.parameters.page = 1
    }
    controller.getManageCourierHistoryUploadBulkList(this.params)
  }

  private onChangePerPage(perPage: number): void {
    this.parameters.perPage = perPage
    this.fetchHistoryUploadBulk(true)
  }

  private headerCellMapper(
    title: string | number,
    colWidth: string,
    paddingRight?: string,
    paddingLeft?: string
  ): IHeaderCell {
    return {
      title: title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
        paddingRight: paddingRight || '0px',
        paddingLeft: paddingLeft || '20px',
      },
    }
  }

  private tableCellMapper(
    value: string | number | Date | string[],
    colWidth: string,
    paddingLeft?: string,
    paddingRight?: string
  ): ITableCell {
    return {
      value,
      customStyle: {
        maxWidth: colWidth,
        minWidth: colWidth,
        paddingRight: paddingRight || '0px',
        paddingLeft: paddingLeft || '24px',
      },
    }
  }

  @Watch('params')
  onParamsChanged(val: Dictionary<string | (string | null)[] | null | undefined> | undefined): void {
    this.$router.replace({
      query: { ...val },
    })
  }

  @Watch('controller.manageCourierHistoryUploadBulkData')
  onChangeHistoryUploadBulkData(data: ManageCourierHistoryUploadBulk[]): void {
    this.tableData = data.map(history => [
      this.tableCellMapper(history.fileName || '-', '255px'),
      this.tableCellMapper(history.createdBy || '-', '255px'),
      this.tableCellMapper(
        history.createdAt
          ? Utils.formatDateWithIDLocale(history.createdAt, 'DD MMMM YYYY')
          : '-',
        '200px'
      ),
      this.tableCellMapper(history.id || '-', '200px'),
    ])
  }
}
