function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.controller.isLoadingGetHistoryUploadBulk)?_c('LoadingOverlay'):_vm._e(),_c('div',{staticClass:"font-bold text-gray-4 py-6"},[_vm._v("History")]),_c('div',{staticClass:"history-upload-table"},[_c('DataTableV2',{attrs:{"headers":_vm.headers,"dataItems":_vm.tableData,"isLoading":_vm.controller.isLoadingGetHistoryUploadBulk},scopedSlots:_vm._u([{key:"3",fn:function(ref){
var rest = objectWithoutProperties( ref, [] );
var data = rest;
return [_c('div',{staticClass:"flex flex-row gap-4 items-center"},[_c('router-link',{staticClass:"flex flex-row gap-1 items-center",attrs:{"to":("history-upload/" + (data.data.value))}},[_c('EyeIcon'),_c('span',{staticClass:"text-bgPrimary text-sm font-semibold"},[_vm._v("Lihat")])],1),_c('div',{staticClass:"flex flex-row gap-1 items-center cursor-pointer",attrs:{"data-testid":"managecourier__history-upload-bulky__download-button"},on:{"click":function () { return _vm.onDownloadFile(data.data.value); }}},[_c('DownloadIcon'),_c('span',{staticClass:"text-bgPrimary text-sm font-semibold"},[_vm._v("Download")])],1)],1)]}}])})],1),_c('div',[(_vm.tableData.length)?_c('PaginationNav',{staticClass:"mb-8 mt-2",attrs:{"enablePerPageOption":"","perPageOptions":_vm.perPageOptions,"page":_vm.parameters.page,"perPage":_vm.parameters.perPage,"totalItem":_vm.controller.paginationData.totalItem},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function () {
          _vm.fetchHistoryUploadBulk()
        })($event)},"input":function () {
          _vm.fetchHistoryUploadBulk()
        },"onChangeOption":_vm.onChangePerPage},model:{value:(_vm.parameters.page),callback:function ($$v) {_vm.$set(_vm.parameters, "page", $$v)},expression:"parameters.page"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }